

























































































import { Component, Vue } from 'vue-property-decorator';
import Breadcrumbs from '@/themes/v1/components/Breadcrumbs.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import StatusLabel from '@/themes/v1/components/StatusLabel.vue';
import SubscribeOnProblem from './SubscribeOnProblem.vue';
import {
  TemporaryProblemsService,
  TemporaryProblemStatus,
  TemporaryProblemViewModel,
} from '@/modules/sts/temporary-problems';
import { SsrCtx, WithAsyncData } from '@/core/vue.types';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import ProblemContent from '@/themes/v1/views/sts/problems/ProblemContent.vue';
import { universalRedirect } from '@/themes/v1/fns/router';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { getModule } from 'vuex-module-decorators';
import { SystemStore } from '@/core/system';
import ProblemMessage from './components/ProblemMessage.vue';
import tail from 'lodash/tail';

@Component({
  components: {
    ProblemContent,
    NeedSomethingElse,
    SubscribeOnProblem,
    StatusLabel,
    BoxContent,
    Box,
    Breadcrumbs,
    ProblemMessage,
  },
})
export default class ProblemView extends Vue implements WithAsyncData {
  problem: TemporaryProblemViewModel = {} as any;

  systemStore = getModule(SystemStore, this.$store);
  showStatuses = false;
  get isClosed() {
    return this.problem.status === TemporaryProblemStatus.Closed;
  }

  get messagesButFirst() {
    return tail(this.problem.messages);
  }

  async asyncData(ctx: SsrCtx): Promise<any> {
    const previewId = ctx.route.params.previewId;
    if (!previewId) {
      universalRedirect(ctx, '/');
      return;
    }
    const temporaryProblemsService = resolveFromCtx<TemporaryProblemsService>(
      'temporaryProblemsService',
      ctx
    );
    const problem = await temporaryProblemsService.preview(
      previewId,
      ctx.app.$i18n.locale
    );
    return { problem };
  }
}
